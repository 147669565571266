import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/layouts/legal.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`PHOTO/VIDEO RELEASE AND RELEASE OF LIABILITY AND ASSUMPTION OF RISK`}</h1>
    <p>{`This Photo/Video Release and Release of Liability and Assumption of Risk (this “`}<strong parentName="p">{`Release`}</strong>{`”) is incorporated into the terms of use agreement (`}<a parentName="p" {...{
        "href": "https://solanaspaces.com/legal/terms"
      }}>{`https://solanaspaces.com/legal/terms`}</a>{`) (the “`}<strong parentName="p">{`Terms of Use`}</strong>{`”) of Sol Stores, Inc. (the “`}<strong parentName="p">{`Company`}</strong>{`”).  Capitalized terms used herein but not defined will have the meanings ascribed to them in the Terms of Use.  By visiting the retail locations of the Company, you understand that you may be involved in activities, events and/or media productions on the premises (collectively, the “`}<strong parentName="p">{`Activities`}</strong>{`”) made available by the Company and/or third parties.  As lawful consideration for being permitted by the Company to participate in the Activities, you agree to all the terms and conditions set forth in this Release. You are referred to below as “I” or “me.”`}</p>
    <p>{`I hereby agree that the Company is authorized to capture, record and/or photograph me, and to use my name, biographical details, image, likeness, actions, voice and/or statements (collectively, “`}<strong parentName="p">{`Likeness`}</strong>{`”), without further authorization or any compensation, worldwide, in any media, whether now known or hereafter devised, for any business purpose (including, without limitation, commercial and promotional purposes).  Company owns all right, title, and interest to any materials, video and/or audio recordings, and photographs incorporating my Likeness that are created hereunder, and any derivative works created therefrom (“`}<strong parentName="p">{`Works`}</strong>{`”).  Company may use, edit, adapt, modify, make derivative works of, publicly perform and publicly display, reproduce, and distribute the Works, in whole or in part, directly and through sublicensees.  I understand Company has no obligation to use my Likeness or any Works.`}</p>
    <p><strong parentName="p">{`I AM AWARE AND UNDERSTAND THAT THE ACTIVITIES MAY BE DANGEROUS AND INVOLVE THE RISK OF SERIOUS INJURY, DEATH AND/OR PROPERTY DAMAGE. I ACKNOWLEDGE THAT ANY INJURIES I SUSTAIN MAY BE COMPOUNDED BY NEGLIGENT EMERGENCY RESPONSE OR RESCUE OPERATIONS OF THE COMPANY. I ACKNOWLEDGE THAT I AM KNOWINGLY AND VOLUNTARILY PARTICIPATING IN THE ACTIVITIES WITH AN EXPRESS UNDERSTANDING OF THE DANGER INVOLVED, AND HEREBY AGREE TO ACCEPT AND ASSUME ANY AND ALL RISKS OF INJURY, DEATH, OR PROPERTY DAMAGE, WHETHER CAUSED BY THE ORDINARY NEGLIGENCE OF THE COMPANY OR OTHERWISE.`}</strong></p>
    <p>{`I hereby expressly waive and release any and all claims actions, causes of action, demands, rights, damages, costs, and expenses which I may have, or which I may hereafter have, whether known or unknown, against the Company, its officers, directors, shareholders, agents, employees, affiliates, successors, assigns, other participants, sponsors, sponsoring agencies, partners and associates, advertisers, and if applicable, owners and lessors of premises used to conduct the event, and each of their respective parent, subsidiary and affiliated companies, officers, directors, partners, shareholders, members, agents, employees and volunteers (“`}<strong parentName="p">{`Releasees`}</strong>{`”), with respect to, arising out of, related to, or in any way connected with (i) the exercise by Company of the rights granted herein, including, without limitation, any and all claims for invasion of privacy, infringement of my privacy, publicity, and/or intellectual property rights, defamation, or portrayal in a false light and (ii) any and all injury, disability, death, or loss or damage to person or property, whether arising from or attributable to the Activities, whether arising out of the ordinary negligence of the Company or any Releasees, or otherwise. I covenant not to make or bring any such claim against the Company or any other Releasee, and forever release and discharge the Company and all other Releasees from liability under such claims. This Release does not extend to claims for gross negligence, intentional or reckless misconduct, or any other liabilities to the fullest extent permitted by law.`}</p>
    <p>{`I understand that by agreeing to this Release, I am waiving any and all claims, of any kind arising out of or attributable to the Activities, including those claims that may be unknown to me, or which I do not suspect to exist at this time. WITH THE INTENTION OF WAIVING ALL UNKNOWN AND UNSUSPECTED CLAIMS, I HEREBY EXPRESSLY WAIVE ALL RIGHTS, BENEFITS, AND PROTECTIONS I MAY HAVE UNDER APPLICABLE STATE LAW AS IT RELATES TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.`}</p>
    <p>{`I shall defend, indemnify, and hold harmless the Company and all other Releasees against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorney’s fees, fees and the costs of enforcing any right to indemnification under this Release, and the cost of pursuing any insurance providers, arising out of or resulting from any claim of a third party related to the Activities, including any claims arising out of my own negligence or the ordinary negligence of the Company.`}</p>
    <p>{`I hereby grant permission to the Company or its designees to administer or arrange for any medical assistance that they deem necessary or appropriate as a result of my participation in the Activities, including, without limitation, arranging transportation to a hospital or other medical facility, and I hereby assume any and all liability for any medical expenses.  I hereby grant to the Company and its designees, access to all medical records (and physicians) as needed. I hereby release and forever discharge the Company and any Releasees from any claim whatsoever which arises or may hereafter arise on account of any first-aid treatment or other medical services rendered in connection with an emergency during the Activities.`}</p>
    <p>{`This Release constitutes the sole and entire agreement between the Company and me with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter. If any term or provision of this Release is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Release, or invalidate or render unenforceable such term or provision in any other jurisdiction.  All matters arising out of or relating to this Release shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction). All disputes arising under this release will be determined solely by arbitration in accordance with the rules of the American Arbitration Association.  The arbitration shall be held in the County of San Francisco, California and the cost thereof, including reasonable attorneys’ fees, shall be borne by the party which does not prevail therein. The arbitrator’s determination shall be final, binding and conclusive upon the parties hereto. Any claim or cause of action arising under this Release may be brought only in the federal and state courts located in San Francisco, California and I hereby consent to the exclusive jurisdiction of such courts. This release cannot be terminated, rescinded or amended, except by a written agreement signed by Company and me.`}</p>
    <p>{`I  represent and warrant that I am eighteen years old or older, (II) ACKNOWLEDGE THAT I HAVE READ AND FULLY UNDERSTOOD ALL OF THE TERMS OF THIS RELEASE OF LIABILITY AND ASSUMPTION OF RISK AGREEMENT AND CONTEMPLATED RISKS, FULLY UNDERSTAND ITS TERMS, (III) UNDERSTAND AND AGREE THAT I AM VOLUNTARILY GIVING UP SUBSTANTIAL LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE THE COMPANY FOR CLAIMS, WHETHER KNOWN OR UNKNOWN, ARISING OUT OF MY PARTICIPATION IN THE ACTIVITIES, and (iV) understand and agree THAT no payments or royalties to, or consents from, any third party are required in connection with Company’s use of any of the rights granted herein.`}</p>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      